import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import cn from 'classnames';

class Loader extends Component {
  render() {
    const { loading, entities, actions, padded, center, color, small, classes, loaderClasses, containerClasses,
            children, show, overlay, overlayScreen, disable } = this.props;

    let entitiesLoading = false;
    _.each(entities.replace(/\s+/g, "").split(','), (entity) => {
      _.each(actions.replace(/\s+/g, "").split(','), (action) => {
        if (loading[`${entity}${_.upperFirst(action)}`]) entitiesLoading = true;
      });
    });


    if (disable || (!entitiesLoading && !show)) {
      return (
        <div
          className={cn(classes, {
            LoaderContent: true
          })}
        >
          {children}
        </div>
      );
    }

    if (overlay) {
      return (
        <div
          className={cn(containerClasses, {
            'Loader-container': true,
            'Loader-container--screen': overlayScreen
          })}
        >
          <div
            className={cn({
              Loader: true,
              'Loader--overlay': true,
              'Loader--padded': padded
            })}
          >
            Loading
          </div>
          {overlayScreen &&
            <div className="Loader-screen">
              {children}
            </div>
          }
        </div>
      );
    }

    if (padded) {
      return (
        <div
          className={cn(containerClasses, {
            'Loader-container': true,
            'Loader-container--padded': padded,
          })}
        >
          <div
            className={cn(loaderClasses, {
              Loader: true,
              'Loader--center': center,
              'Loader--small': small,
              'Loader--white': (color === 'white')
            })}
          >
            Loading
          </div>
        </div>
      );
    }

    return (
      <div
        className={cn(loaderClasses, {
          Loader: true,
          'Loader--center': center,
          'Loader--padded': padded,
          'Loader--small': small,
          'Loader--white': (color === 'white')
        })}
      >
        Loading
      </div>
    );
  }
}

Loader.propTypes = {
  entities: PropTypes.string,
  actions: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool,
  center: PropTypes.bool,
  loading: PropTypes.object,
  overlay: PropTypes.bool,
  overlayScreen: PropTypes.bool,
  padded: PropTypes.bool,
  small: PropTypes.bool,
  classes: PropTypes.string
};

Loader.defaultProps = {
  show: false,
  padded: false,
  overlay: false,
  center: false,
  color: null,
  small: false,
  overlayScreen: false,
  entities: '',
  actions: ''
};

function mapStateToProps(state, props) {
  return {
    loading: state.ui.loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
