// See React on Rails Pro documentation for details regarding **.imports-X.** file extension & duplicate file structure.
import loadable from '@loadable/component';
import retry from '../utils/retry';

// Components
export const Footer = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Footer" */
  './shared/Footer')));

// Routes
export const Admin = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Admin" */
  './admin/Admin')));
export const VendorDashboard = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "VendorDashboard" */
  './vendor/VendorDashboard')));
export const CateringDashboard = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "CateringDashboard" */
  './catering/CateringDashboard')));
export const TruckManage = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "TruckManage" */
  './vendor/truck/TruckManage')));
export const NewTruck = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "NewTruck" */
  './vendor/truck/new_truck/NewTruck')));
export const UnsubscribeShiftAvailable = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "UnsubscribeShiftAvailable" */
'./vendor/UnsubscribeShiftAvailable')));
export const ManageCateringRequest = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "ManageCateringRequest" */
'./catering/ManageCateringRequest')));
export const CustomerCateringRequestDetail = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "CustomerCateringRequestDetail" */
'./catering/CustomerCateringRequestDetail')));
export const Contact = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Contact" */
'./catering/Contact')));
export const Account = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Account" */
'./auth/Account')));
export const ForgotPassword = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "ForgotPassword" */
'./auth/ForgotPassword')));
export const ChangePassword = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "ChangePassword" */
'./auth/ChangePassword')));
export const Error404Page = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Error404Page" */
'./public/Error404Page')));
export const Error500Page = loadable(() => retry(() => import(
  /* webpackPrefetch: true */
  /* webpackChunkName: "Error500Page" */
'./public/Error500Page')));