// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

// Selectors
import { getCityConfig } from '../../selectors';

import IsoMorphUtils from '../../modules/isoMorphUtils';

const homeJSON = (cityConfig) => {
  return {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "@id": cityConfig.base_url,
    "name": cityConfig.site_title_spaces,
    "telephone": "+12067990095",
    "url": cityConfig.base_url,
    "logo": cityConfig.base_url + cityConfig.logo,
    "image": "https://static.seattlefoodtruck.com/photos/homehero.jpg",
    "telephone": "+12067990095",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "113 Cherry St #45647",
      "addressLocality": "Seattle",
      "addressRegion": "WA",
      "postalCode": "98104",
      "addressCountry": "US"
    },
    "publicAccess": false,
  }
};

const searchJSON = (cityConfig) => {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": cityConfig.base_url,
    "potentialAction": {
      "@type": "SearchAction",
      "target": cityConfig.base_url + "/search?query={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  }
};

const BlogPostJSON = (structuredData) => {
  return {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": structuredData.blogPost.headline,
    "image": structuredData.blogPost.image,
    "datePublished": structuredData.blogPost.datePublished,
    "dateModified": structuredData.blogPost.dateModified,
  };
}

const BreadcrumbsJSON = (cityConfig, structuredData) => {
  let listItems = structuredData.breadcrumbs.listItems;
  if (listItems) {
    listItems.unshift(
      {
        "@type": "ListItem",
        "position": 1,
        "name": cityConfig.site_title_spaces,
        "item": cityConfig.base_url,
      }
    );
  }

  return {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": listItems,
  };
}

class Meta extends Component {
  render() {
    const { title, description, url, noindex, structuredData, type, image, preloadImg, cityConfig } = this.props;
    const defaultTitle = `${cityConfig.site_title} | Find ${cityConfig.city_name}'s Best Food ${cityConfig.vehicle_type}s`;
    const defaultDescription = `Your resource for all things street food & catering. Check our schedule to find where your next meal is parked or book a food ${cityConfig.vehicle_type.toLowerCase()} for your next event.`;

    let schemaList = [];
    if (structuredData) {
      if (structuredData.home) schemaList.push(homeJSON(cityConfig));
      if (structuredData.home) schemaList.push(searchJSON(cityConfig));
      if (structuredData.blogPost) schemaList.push(BlogPostJSON(structuredData));
      if (structuredData.breadcrumbs) schemaList.push(BreadcrumbsJSON(cityConfig, structuredData));
    }

    return (
      <Helmet defer={false}>
        <title>{title ? title : defaultTitle}</title>
        <meta name="description" content={description ? description : defaultDescription} />
        <meta itemprop="name" content={title} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content={type ? type : "article"} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={url} />
        <meta property="og:description" content={description ? description : defaultDescription} />
        <meta property="og:site_name" content={cityConfig.site_title} />
        <meta property="article:publisher" content={cityConfig.facebook} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description ? description : defaultDescription} />
        <meta name="twitter:site" content={"@" + cityConfig.twitter} />
        <meta name="twitter:creator" content={"@" + cityConfig.twitter} />

        { !image && <meta name="twitter:card" content="summary_large_image" /> }
        { !image && <meta name="twitter:image" content={cityConfig.base_url + cityConfig.logo_png} /> }
        { !image && <meta property="og:image" content={cityConfig.base_url + cityConfig.logo_png} /> }
        { !image && <meta name="twitter:image" content={cityConfig.base_url + cityConfig.logo_png} /> }

        { image && <meta name="twitter:card" content="summary_large_image" /> }
        { image && <meta name="twitter:image" content={image} /> }
        { image && <meta itemprop="image" content={image} /> }
        { image && <meta property="og:image" content={image} /> }

        {noindex &&
          <meta name="robots" content="noindex" />
        }
        { structuredData &&
          <script className='structured-data-list' type="application/ld+json">{JSON.stringify(schemaList)}</script>
        }
        { preloadImg &&
          <link rel="preload" as="image" href={preloadImg} />
        }
      </Helmet>
    );
  }
}

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  noindex: PropTypes.bool,
  cityConfig: PropTypes.object,
};

Meta.defaultProps = {
  url: `${IsoMorphUtils.railsContext.scheme}:${IsoMorphUtils.railsContext.href}`,
  noindex: false
};

function mapStateToProps(state, props) {
  return {
    cityConfig: getCityConfig(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Meta);
