// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { clearError } from '../../actions/errorActions';

import styles from './Modal.module.postcss';

class ErrorModal extends Component {
  constructor(props, _railsContext) {
    super(props);
  }

  render() {
    const { errors } = this.props;

    if (!errors.modal) {
      return null;
    }

    return (
      <div className={styles.ModalContainer}>
        <div className={styles.ModalContent + " " + styles.ModalContent__center + " " + styles.ModalContent__morePadding}>
          <h2>There was an error 🙁</h2>
          {errors.message && errors.message !== 'error' &&
            <p>{errors.message}</p>
          }
          <button
            className="Button"
            onClick={this.props.clearError}
          >
            Ok
          </button>
        </div>
      </div>
    );
  }
}

ErrorModal.propTypes = {
  clearError: PropTypes.func,
  errors: PropTypes.object
};

ErrorModal.defaultProps = {
};

function mapStateToProps(state) {
  return {
    errors: state.errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearError() {
      dispatch(clearError());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
